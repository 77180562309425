import {
  ControlAttributesMetadata,
  GetControlDataOutput,
  RecordAttributes,
} from '@amzn/chub-model-typescript-client';
import {
  AppLayout,
  ContentLayout,
  Flashbar,
  Header,
  SpaceBetween,
  Spinner,
  FlashbarProps,
  Tabs,
} from '@cloudscape-design/components';
import React, { useEffect, useState, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import ToolsNavGRC5257 from '../navigation/ToolsNavGRC5257';
import TopNav from '../navigation/TopNav';
import { apiCall } from 'src/utils/ApiCall';
import { Breadcrumbs } from 'src/common/Breadcrumbs';
import { useControls } from 'src/common/ControlContext';
import { ControlSummaryTable } from './ControlSummaryTable';
import { ControlSummaryPageAGGrid } from './ControlSummaryPageAGGrid';
import ControlMetadataContent from 'src/common/ControlMetadataContent';
import { useUserAlias } from 'src/common/hooks/useUserAlias';
import { GetControlAttributesMetadataResponse } from 'src/utils/ApiInterfaces';
import { ApiPaths } from 'src/common/config/ApiConstants';
import { logger } from 'src/logger';

/**
 * Check if a record is empty
 * @param {RecordAttributes[]} record - The record to check
 * @returns {boolean} True if the record is empty, false otherwise
 */
export const isRecordEmpty = (record: RecordAttributes[]): boolean => {
  // If the record is null or empty, consider it empty
  if (record == null || record.length === 0) return true;
  // Check if all attributes in the record are empty (empty string or null)
  return record.every((attr) =>
    Object.values(attr).every((value) => value === '' || value === null)
  );
};

/**
 * Filter out empty records from the data
 * @param {RecordAttributes[][]} data - The data to filter
 * @returns {RecordAttributes[][]} Filtered data with non-empty records
 */
export const filterAndCleanRecords = (data: RecordAttributes[][]): RecordAttributes[][] => {
  return data.filter((record) => !isRecordEmpty(record));
};

export const SummaryPage = () => {
  const { alias } = useUserAlias();
  const { controls } = useControls();
  const [activeTabId, setActiveTabId] = useState('table');
  const [controlAttributesData, setControlAttributesData] =
    React.useState<ControlAttributesMetadata | null>(null);
  const [controlData, setControlData] = useState<RecordAttributes[][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [items, setItems] = useState<Record<string, any>[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { controlId } = useParams<{ controlId: string }>();
  const safeControlId = controlId ?? '';
  const [errorMessages, setErrorMessages] = useState<readonly FlashbarProps.MessageDefinition[]>(
    []
  );

  // Memoize the control object based on the controlId
  const control = useMemo(() => {
    // Find the control in the controls array that matches the controlId, or return null if not found
    return controls.find((c) => c.controlId === controlId) || null;
  }, [controls, controlId]);

  // Effect to fetch control attributes
  useEffect(() => {
    const fetchControlAttributes = async () => {
      try {
        // Make an API call to get control attributes metadata
        const data = await apiCall<GetControlAttributesMetadataResponse>({
          endpoint: ApiPaths.getControlAttributes + `/${controlId}`,
        });
        // Update the state with the fetched data
        setControlAttributesData(data.controlAttributesMetadata);
      } catch (err) {
        setError('Unknown API error has occurred');
      }
    };
    fetchControlAttributes();
  }, []); // This effect runs once on component mount

  // Effect hook to fetch and process control data
  useEffect(() => {
    let isMounted = true; // Flag to track if component is mounted

    // Asynchronous function to fetch data
    const fetchData = async () => {
      let hasNextPage: string | undefined = ' ';
      const limit = 750; // Number of records to fetch per API call
      try {
        // Loop to fetch paginated data
        do {
          // Make an API call to get control data
          const data: GetControlDataOutput = await apiCall<GetControlDataOutput>({
            endpoint: ApiPaths.getControlData + `/${controlId}/`,
            queryParams: { limit: limit, exclusiveStartKey: hasNextPage },
          });

          // Filter out completely empty records
          const filteredRecords = filterAndCleanRecords(data.controlData?.records ?? []);
          hasNextPage = data.lastEvaluatedKey; // Get the key for the next page
          // Update state only if component is still mounted
          if (isMounted) {
            // Update the state with the new filtered records
            setControlData((prevData) => [...prevData, ...filteredRecords]);
          }
        } while (hasNextPage !== undefined && hasNextPage);
      } catch (error) {
        // Log error if component is still mounted
        if (isMounted) {
          logger.error('Error fetching data: ' + error);
        }
      } finally {
        // Set loading state to false when done, if component is still mounted
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    fetchData(); // Call the fetch function

    // Cleanup function to prevent state updates if component unmounts
    return () => {
      isMounted = false;
    };
  }, []); // This effect runs once on component mount

  /**
   * Render the table content based on the current state
   * @returns {JSX.Element} The rendered table or loading message
   */
  const renderTableContent = () => {
    // If control attributes data is available and control data is not empty
    if (controlAttributesData && controlData.length > 0) {
      // Render the ControlSummaryTable component
      return (
        <ControlSummaryPageAGGrid
          controlData={controlData}
          controlAttributesData={controlAttributesData}
          isLoading={isLoading}
          controlId={safeControlId}
        />
      );
    } else {
      // If data is not ready, render a loading message using Flashbar
      return (
        <Flashbar
          items={[
            {
              type: 'warning',
              loading: true,
              content: 'Data is loading. Please wait for filtering and control execution',
              dismissible: false,
              dismissLabel: 'Dismiss message',
              onDismiss: () => setItems([]),
              id: 'message_1',
            },
          ]}
        />
      );
    }
  };

  useEffect(() => {
    if (error) {
      setErrorMessages([
        {
          header: 'Error',
          type: 'error' as const,
          content: error.toString(),
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setErrorMessages([]),
          id: 'error_message',
        },
      ]);
    } else {
      setErrorMessages([]);
    }
  }, [error]);

  return controls.length > 0 ? (
    <>
      <div id="b">
        <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
          <TopNav
            userAlias={alias}
            issueLink="https://sim.amazon.com/issues/create?assignedFolder=cb133b70-fac3-42e7-86f2-575d040a086d"
            aboutLink="https://w.amazon.com/bin/view/WWCF_IPAT/FinTech/Controllership/Controllership_Hub/"
          />
        </div>
        <Outlet />
      </div>
      <Flashbar items={errorMessages} />
      <AppLayout
        navigationHide={true}
        disableContentPaddings={false}
        toolsHide={true}
        toolsWidth={500}
        maxContentWidth={Number.MAX_VALUE}
        onToolsChange={async () => {
          console.log('tools changed');
        }}
        content={
          <>
            <ContentLayout
              defaultPadding={false}
              disableOverlap={true}
              maxContentWidth={Number.MAX_VALUE}
              headerVariant={'default'}
              header={
                <Header variant="h1" description="">
                  {control?.controlName} - {control?.controlSubTitle}
                </Header>
              }
            >
              {location.pathname !== '/' ? (
                <Breadcrumbs
                  items={[
                    { text: 'Controllership Hub', href: '/' },
                    {
                      text: `Control Home`,
                      href: `/${control?.controlType?.toLowerCase()}/${encodeURIComponent(safeControlId)}`,
                    },
                    { text: `Control Data`, href: '' },
                  ]}
                />
              ) : undefined}
              <SpaceBetween size="l">
                <Tabs
                  activeTabId={activeTabId}
                  onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
                  tabs={[
                    {
                      label: 'Control Data',
                      id: 'table',
                      content: renderTableContent(),
                    },
                    {
                      label: 'Information',
                      id: 'information',
                      content: (
                        <ControlMetadataContent
                          controlAttributesData={controlAttributesData}
                          control={control}
                        />
                      ),
                    },
                  ]}
                />
              </SpaceBetween>
            </ContentLayout>
          </>
        }
      />
    </>
  ) : (
    <Spinner />
  );
};
