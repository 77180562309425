import { ColDef, GridOptions, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { AttributeMetadata } from '@amzn/chub-model-typescript-client';
import './styles.scss';

/**
 * Creates column definitions for an AG Grid based on attribute metadata
 * @param controlAttributesData - Contains metadata for grid columns/attributes
 * @param editedCells - Set containing IDs of cells that have been modified
 * @param CustomCellPopoverRenderer - Custom renderer component for modal view cells
 * @returns Array of column definitions for AG Grid
 */
export const createColumnDefs = (
  controlAttributesData: any,
  editedCells: Set<string>,
  CustomCellPopoverRenderer: any
): ColDef[] => {
  if (!controlAttributesData) return [];

  return (
    controlAttributesData.attributesMetadata
      ?.map((attr: AttributeMetadata): ColDef | null => {
        const fieldName = attr.name;
        if (!fieldName) {
          console.warn('Attribute name is undefined', attr);
          return null;
        }

        // Special handling for Classification Reason column
        // TODO: Add this as an backend config to handle specific columns scenario
        if (fieldName === 'Classification Reason') {
          return {
            field: fieldName,
            headerName: attr.displayName || fieldName,
            editable: attr.isEditable || false,
            minWidth: 450, // Increased minimum width
            width: 500, // Default width
            flex: 1, // Give more flex space to this column
            autoHeight: true,
            wrapText: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellRenderer: (params: any) => {
              if (!params.value) return '';

              // Split by </p> to separate paragraphs and clean up
              const paragraphs = params.value
                .split('</p>')
                .map((p: string) => p.replace(/<p>/g, '').trim())
                .filter((p: string) => p.length > 0);

              // Join paragraphs with line breaks
              return paragraphs.join('\n');
            },
            cellStyle: (params: any) => ({
              whiteSpace: 'pre-line', // Preserve line breaks
              padding: '10px', // Add some padding
              lineHeight: '1.5', // Improve line spacing
              height: 'auto', // Allow height to adjust to content
              overflow: 'visible', // Show all content
              ...(editedCells.has(`${params.node.id}-${params.column.colId}`)
                ? { backgroundColor: '#5CA3EE' }
                : {}),
            }),
          };
        }

        // Regular column definition for other columns
        // TODO: Add datatype, cellEditor value as backend config
        return {
          field: fieldName,
          headerName: attr.displayName || fieldName,
          editable: attr.isEditable || false,
          wrapText: true,
          autoHeight: false,
          resizable: true,
          minWidth: 200,
          flex: 1,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          cellDataType: attr.dataType === 'java.lang.Boolean' ? 'boolean' : 'text',
          cellEditor:
            attr.dataType === 'java.lang.Boolean'
              ? 'agCheckboxCellEditor'
              : attr.dataType === 'java.util.List'
                ? 'agSelectCellEditor'
                : undefined,
          cellEditorParams:
            attr.dataType === 'java.util.List'
              ? {
                  values: ['Yes', 'No'],
                }
              : undefined,
          cellRenderer:
            attr.dataType === 'java.lang.Boolean'
              ? 'agCheckboxCellRenderer'
              : attr.enableModalView
                ? CustomCellPopoverRenderer
                : undefined,
          cellRendererParams:
            attr.dataType === 'java.lang.Boolean'
              ? {
                  checkbox: true,
                }
              : undefined,
          valueGetter:
            attr.dataType === 'java.lang.Boolean'
              ? (params: ValueGetterParams) => {
                  const value = (params.data as Record<string, any>)?.[fieldName];
                  return value === true || value === 'true';
                }
              : undefined,
          valueSetter:
            attr.dataType === 'java.lang.Boolean'
              ? (params: ValueSetterParams) => {
                  const data = params.data as Record<string, any>;
                  data[fieldName] = params.newValue === true || params.newValue === 'true';
                  return true;
                }
              : undefined,
          cellStyle: (params: any) => {
            const isEdited = editedCells.has(`${params.node.id}-${params.column.colId}`);
            return {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...(isEdited ? { backgroundColor: '#5CA3EE' } : {}),
            };
          },
          headerClass: 'no-wrap-header',
        };
      })
      .filter((colDef: ColDef | null): colDef is ColDef => colDef !== null) ?? []
  );
};

/**
 * Creates grid options configuration for AG Grid
 * @param defaultColDef - Default column properties
 * @param rowData - Array of data to be displayed in the grid
 * @param columnDefs - Column definitions for the grid
 * @param onCellValueChanged - Callback function for cell value changes
 * @param onGridReady - Callback function when grid is initialized
 * @returns GridOptions configuration object
 */
export const createGridOptions = (
  defaultColDef: any,
  rowData: any[],
  columnDefs: ColDef[],
  onCellValueChanged: any,
  onGridReady: any
): GridOptions => ({
  defaultColDef: {
    ...defaultColDef,
    resizable: true,
    suppressSizeToFit: false,
  },
  rowData,
  columnDefs,
  onCellValueChanged,
  suppressColumnVirtualisation: true,
  suppressRowClickSelection: true,
  pagination: true,
  paginationPageSize: 300,
  paginationPageSizeSelector: [100, 200, 300, 500],
  animateRows: true,
  rowSelection: 'multiple',
  onGridReady: onGridReady,
});
