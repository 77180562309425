import React, { useState } from 'react';
import { Button, SpaceBetween, Popover, Badge, Box } from '@cloudscape-design/components';
import { FilterModel } from 'ag-grid-community';

interface ActiveFiltersProps {
  filters: FilterModel;
  onClearFilter: (column: string) => void;
  onClearAllFilters: () => void;
}

/**
 * Component that displays active filters and provides controls to manage them
 * @param filters - Object containing active filter configurations
 * @param onClearFilter - Callback function to clear a specific filter
 * @param onClearAllFilters - Callback function to clear all active filters
 */
export const ActiveFilters: React.FC<ActiveFiltersProps> = ({
  filters,
  onClearFilter,
  onClearAllFilters,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const filterCount = Object.keys(filters).length;

  if (filterCount === 0) {
    return null;
  }

  /**
   * Extracts displayable value from filter configuration
   * @param filter - Filter configuration which can be string, object with 'filter' or 'value' property
   * @returns Formatted string representation of the filter value
   */
  const getFilterDisplayValue = (filter: any): string => {
    if (typeof filter === 'string') {
      return filter;
    }
    if (filter && typeof filter === 'object') {
      if ('filter' in filter) {
        return filter.filter;
      }
      if ('value' in filter) {
        return filter.value;
      }
    }
    return JSON.stringify(filter);
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Popover
        dismissButton={true}
        position="bottom"
        size="large"
        triggerType="custom"
        dismissAriaLabel="Close active filters popover"
        header="Active Filters"
        content={
          <SpaceBetween direction="horizontal" size="s">
            {Object.entries(filters).map(([column, filter]) => (
              <Box key={column}>
                {column}: <span style={{ color: '#1D8102' }}>{getFilterDisplayValue(filter)}</span>
                <Button
                  variant="icon"
                  iconName="remove"
                  onClick={() => onClearFilter(column)}
                  aria-Label={`Remove ${column} filter`}
                />
              </Box>
            ))}
          </SpaceBetween>
        }
      >
        <Button
          iconName="filter"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-haspopup="true"
        >
          Active Filters: <Badge color="blue">{filterCount}</Badge>
        </Button>
      </Popover>
      {filterCount > 0 && (
        <Button onClick={onClearAllFilters} iconName="close">
          Clear All Filters
        </Button>
      )}
    </SpaceBetween>
  );
};
