import React from 'react';
import { SpaceBetween, HelpPanel } from '@cloudscape-design/components';

/**
 * ToolsNavGRC5257 Component
 *
 * This component renders a HelpPanel containing information about the Controllership Hub.
 * It provides an overview of key features, user benefits, and specific GRC controls
 * implemented in the Controllership Hub.
 *
 * @component
 * @example
 * return (
 *   <ToolsNavGRC5257 />
 * )
 */
const ToolsNavGRC5257: React.FC = () => (
  <HelpPanel header={'GRC 5257 - ARO Quarterly Control'}>
    <div>
      <SpaceBetween size={'l'}>
        <ol>
          <p>
            <b>Note:</b>The thresholds to determine if a change is “significant” are as follows:
          </p>
          <p>
            <b>Q1-Q3:</b> The quarter over quarter (QoQ) change is “significant” if one of the
            following is true:
          </p>

          <ul>
            <li>
              The material change in LHI or heavy equipment represents an increase greater than $500
              thousand and increase greater than 15%, OR
            </li>
            <li>A decrease greater than $500k and decrease greater than 50%</li>
          </ul>
          <p />
          <p>The year over year (YoY) change is “significant” if one of the following is true:</p>
          <ul>
            <li>
              The material change in LHI or heavy equipment represents an increase greater than $1
              million and increase greater than 25%, OR
            </li>
            <li>A decrease greater than $1 million and decrease greater than 50%</li>
          </ul>
          <p />
          <p>
            <b>Q4:</b> The quarter over quarter (QoQ) and year over year (YoY) change is
            “significant” if one of the following is true:
          </p>

          <ul>
            <li>
              The material change in LHI or heavy equipment represents an increase greater than $500
              thousand and increase greater than 15%, OR
            </li>
            <li>A decrease greater than $500k and decrease greater than 50%</li>
          </ul>
          <p />
          <p>
            For example, if the QoQ/YoY change in LHI/ heavy equipment in Q4 is 18% and material
            value of this change is $300k, it is not considered significant. If the QoQ/YoY change
            in LHI/ heavy equipment in Q2 is 22% and material value of this change is $1.1M, it is
            also not considered significant.
          </p>
        </ol>
      </SpaceBetween>
    </div>
  </HelpPanel>
);

export default ToolsNavGRC5257;
